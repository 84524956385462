import React from 'react'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import Vid from "../components/MyProfile/content/Vid";
import poster from '../static/posters/nov-20/3.png'


const VIDEO_URL = 'https://waxcarvers-videos.s3.eu-west-1.amazonaws.com/7-free-filing-tips/7-free-filing-tips.m3u8'
const tracks = [{
    src: `${VIDEO_URL.substring(0, VIDEO_URL.lastIndexOf('/'))}/en.vtt`,
    kind: 'captions',
    srclang: 'en',
    label: 'English'
}]
const Index = () => (
    <Layout>
        <div className="bg-gray-50">
            <div className="relative overflow-hidden">
                <div className="relative py-6">
                    <div className="mt-6 mx-auto max-w-screen-md sm:px-6">
                        <div className="text-leading">
                            <h1
                                className="text-center tracking-tight leading-10 font-bold text-gray-900 sm:text-4xl sm:leading-none md:text-4xl">
                                7 filing tips and tricks

                                <span className="text-blue-700"> </span>
                            </h1>

                            <h3
                                className="mt-4 text-center tracking-tight leading-10 text-gray-700 sm:text-xl sm:leading-none md:text-xl">
                                Enjoy this 5 minute video and learn 7 ways to improve your filing.

                                <span className="text-blue-700"> </span>
                            </h3>
                            <Vid
                                className="self-center"
                                videoJsOptions={{

                                    description: "Charms",
                                    name: "Account Walkthrough",
                                    urls: [VIDEO_URL],
                                    video: {
                                        poster,
                                        autoplay: false,
                                        controls: true,
                                        src: VIDEO_URL,
                                        type: "application/x-mpegURL",
                                        tracks
                                    }
                                }}
                            />
                            <p
                                className="px-3 mt-1  prose prose-indigo prose-lg max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-l md:max-w-3xl">
                                <br />
                                Of course there is more to wax carving than just filing. But filing will get you far. It's a good place to start improving your skills!
                                <br /><br />
                                Are you interested in learning more wax carving techniques? Take a look at the available classes. You can learn different skills and techniques each class.
                                <br /><br />
                                And did you know that most wax carving techniques are totally transferable to working in metal?
                                <br /><br />





                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative pt-6 pb-12 lg:pb-20">
                    <div className="mx-auto max-w-screen-xl px-4 sm:px-6">
                        <div className="text-center">
                            <h2
                                className="text-md tracking-tight leading-10 font-bold text-gray-800 sm:text-xl sm:leading-none md:text-2xl">
                                Want to learn more?
                            </h2>
                            <p className="mt-3 max-w-md mx-auto text-base text-white sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                                <button className="bg-blue-700 rounded-md shadow py-3 px-6" aria-label="Join newsletter">
                                    <Link to="/classes">Check available classes</Link>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default Index
